.tabela-scroll {
  overflow: auto;
}
.table thead tr {
  line-height: 10px !important;
  background-color: #ffffff;
  /* font-family: "Poppins", sans-serif; */
  font-size: 14px;
  color: #504f4f;
}
.table tbody tr {
  line-height: 6px !important;
}
.table tbody tr td span {
  line-height: 6px !important;
}
.span-acoes {
  /* margin-top: -5px; */
  margin-bottom: -10px; /*Faz a linha do grid ficar com uma altura boa devido os botões das ações */
  line-height: 10px !important;
}
.dropdown-acoes {
  margin-top: -15px;
  margin-bottom: -10px; /*Faz a linha do grid ficar com uma altura boa devido os botões das ações */
  line-height: 10px !important;
}
.btn-vertical {
  margin-top: 20px;
  max-height: 23px;
}
.icon-vertical {
  margin-top: -13px;
  max-height: 25px !important;
}

.table-striped tbody tr:nth-child(odd) td,
.table-striped tbody tr:nth-child(odd) th {
  background-color: #f4f7f9;
}

.table-striped tbody tr:nth-child(even) td,
.table-striped tbody tr:nth-child(even) th {
  background-color: #ffffff;
}

.table_td {
  line-height: 110%;
  overflow-wrap: "break-all";
}

.table_head {
  color: white;
  background-color: #669966;
  /* font-family: "Poppins"; */
  font-size: 14px;
}

.table_body {
  /* font-family: "Poppins"; */
  font-size: 12px;
  text-align: center;
  line-height: 14px !important;
}

.table_body_regist {
  /* font-family: "Poppins"; */
  font-size: 12px;
  text-align: center;
  line-height: 16px !important;
}

.even_row {
  background-color: #f1f1f1;
}
.even_row:hover {
  background-color: #f1f1f1 !important;
}

.odd_row {
  background-color: white;
}
.odd_row:hover {
  background-color: white !important;
}

.locked_row {
  color: #9e9e9e !important;
  background-color: #d8e4de;
}
.locked_row:hover {
  background-color: #d8e4de !important;
}
