#nav-dropdown {
  color: #fff;
  font-size: 12px;
  /* font-family: "Poppins" !important; */
  font-weight: 400;
  /* background-color: "red"; */
  text-decoration: none;
  transition-duration: 0.4s;
}

#nav-dropdown:hover {
  color: "#eee" !important;
}

.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}

#dropdown-item {
  color: #4b4b4b;
  /* font-family: "Poppins" !important; */
  font-size: 12px;
}

#dropdown-item.active,
#dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  color: #4b4b4b;
  background-color: #eee;
}
