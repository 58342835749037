.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
  overflow: visible !important;
}

.css-levciy-MuiTablePagination-displayedRows {
  font-size: 12px !important;
}

/* .MuiButtonBase-root {
  color: #fff !important;
} */

.css-1pe4mpk-MuiButtonBase-root-MuiIconButton-root {
  color: #fff !important;
}

.datagrid_head {
  color: #fff;
  background-color: #669966 !important;
  /* font-family: "Poppins"; */
  font-weight: bold !important;
  font-size: 14px;
}

.css-13cymwt-control {
  height: "2rem";
  /* font-family: "Poppins"; */
  font-size: 12 !important;
}

.css-qbdosj-Input {
  /* font-family: "Poppins"; */
  font-size: 12 !important;
}

.css-1hjr4i9-container {
  line-height: 1rem;
}

.css-ql19fo-MuiDataGrid-overlayWrapper {
  z-index: -2 !important;
}

.MuiInputLabel-root {
  /* font-family: "Poppins" !important; */
  font-size: 14px !important;
}

.css-vqmohf-MuiButtonBase-root-MuiRadio-root {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.css-bdhsul-MuiTypography-root-MuiDialogTitle-root {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.css-hyum1k-MuiToolbar-root {
  min-height: 42px !important;
}

@media (min-width: 600px) {
  .css-hyum1k-MuiToolbar-root {
    min-height: 40px !important;
    margin-left: "10px !important";
  }
}

@media (min-width: 600px) {
  .css-i4bv87-MuiSvgIcon-root.css-8je8zh-MuiTouchRipple-root {
    margin-left: 10;
  }
}

@media (min-width: 600px) {
  .css-i6s8oy {
    min-height: 40px !important;
  }
}
