.drop_area {
  margin-top: 1rem;
  margin-bottom: 0.2rem;

  padding: 2rem 0.5rem !important;
  /* font-family: "Poppins"; */

  border-style: solid !important;
  border-color: #eee !important;

  transition-duration: 0.2s;
}

.drop_area:hover {
  border-style: solid !important;
  border-color: #1786c0 !important;
}
